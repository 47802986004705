import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ 
    "ai_type", "language",  
    "audience_interests", "intent", "lifestyle", "brand_pref",
    "format", "number", "emoji", "style", "audience", "promote",
    "core_msg", "variations", "add_format",
    "container_1", "container_2" ]

  static values = {
    url: String,
    url2: String
  }

  initialize() {
    $(this.ai_typeTarget).focus()
    this.index = 0
    this.answer = ""
  }

  typewriter_1() {
    if (this.index < this.answer.length) {
        this.container_1Target.innerHTML += this.answer.charAt(this.index);
        this.index++;
        setTimeout(this.typewriter_1.bind(this), 3);
    }
  }

  typewriter_2() {
    if (this.index < this.answer.length) {
        this.container_2Target.innerHTML += this.answer.charAt(this.index);
        this.index++;
        setTimeout(this.typewriter_2.bind(this), 3);
    }
  }

  autoSize(event) {
    var el = $(event.target)[0];
    el.style.height = 0;
    el.style.height = (el.scrollHeight) + "px";
  }

  clear(event) {
    $(event.target).closest('.inputs').find('input, textarea').not('input[type="submit"]').val('');
    $(event.target).closest('.inputs').find('input, textarea').first().focus();
    var id = $(event.target).closest('.inputs').attr('id').slice(-1);
    $("#container_" + id)[0].innerHTML = '';
    $("#counter_" + id).text('0 words');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(event.target).closest('.inputs').offset().top
    }, 1000);  
  }

  copy(event) {
    var $temp = $("<textarea>");
    $("body").append($temp);
    $temp.val($(event.target).parent().nextAll('div:first').text()).select();
    document.execCommand("copy");
    $temp.remove();
    $('.flashes').show();
    setTimeout(() => { $('.flashes').hide()} , 2000);
  }

  countWords(s) {
      s = s.replace(/(^\s*)|(\s*$)/gi,"");
      s = s.replace(/[ ]{2,}/gi," ");
      s = s.replace(/\n /,"\n"); 
      return s.split(' ').filter(function(str){ return str!=""; }).length;
  }

  styleChoosen(event) {
    if($(event.target).parent().find('input[type=radio]').val() == 0)
      $('textarea[name=style]').parents('li').show();
    else
      $('textarea[name=style]').parents('li').hide();
  }
  
	styleSample(event) {
		event.preventDefault();
		window.loader = $("#dialog-loader").dialog({
		    dialogClass: "no-close",
		    modal: true
		});
		window.query_dialog = $('#seo-queries').dialog({
		  autoOpen: false,
		  height: 640,
		  width: 800,
		  modal: true,
		  buttons: {
		    Cancel: function() {
		      window.query_dialog.dialog('close');
		    }
		  }
		});
		setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'}); $('.ui-widget-overlay').css({height: $( document ).height() + 'px'})}, 50);
		var input_data = { 
			authenticity_token: $('meta[name="csrf-token"]').attr('content'),
		  style_id: $(event.target).parent().find('input[type=radio]').val()
		}
		$.ajax({
		  context: this,
		  async: true,
		  type: "POST",
		  url: this.url2Value,
		  data: input_data,
		  dataType: 'html'
		}).done(function(data) {
	  		window.loader.dialog('close'); 
		    setTimeout((function() {
		      $('#seo-queries-content').html(data);
					setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'}); $('.ui-widget-overlay').css({height: $( document ).height() + 'px'})}, 50);
		      window.query_dialog.dialog('open');
		    }), 100);
		}).fail(function( jqXHR, textStatus ) {
	  	  window.loader.dialog('close');
				alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
		});
	}
  
  generate(event) {
    event.preventDefault();
    // window.loader = $("#dialog-loader").dialog({
    //     dialogClass: "no-close",
    //     modal: true
    // });
    setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'})}, 50);
    var input_data = { 
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      ai_type: this.ai_typeTarget.value,
      language: this.languageTarget.value,
      audience_interests: $('select[name="audience_interests"]').val(), 
      intent: $('select[name="intent"]').val(),
      lifestyle: $('select[name="lifestyle"]').val(), 
      brand_pref: $('select[name="brand_pref"]').val(),
      model: $('input[name="model"]:checked').val()
    }
    var fieldset = $(event.target).closest('.inputs').attr('id');
    switch (fieldset) {
      case "input-fields-1": input_data = {
          ...input_data,
          type: 1,
          format: $('select[name="format"]').val(),
          number:  this.numberTarget.value,
          emoji: $('input[name="emoji"]:checked').val(),
          style_sample: ($('input[name="style_sample"]:checked').val() == undefined) ? 0 : $('input[name="style_sample"]:checked').val(),
          style: this.styleTarget.value,
          audience: this.audienceTarget.value,
          promote: this.promoteTarget.value,
          content: this.container_1Target.textContent
        }
        break;
      case "input-fields-2": input_data = {
          ...input_data,
          type: 2,
          core_msg: this.core_msgTarget.value,
          variations: this.variationsTarget.value,
          add_format: $('select[name="add_format"]').val(),
          content: this.container_2Target.textContent
        }
        break;
    }
    $.ajax({
      context: this,
      async: true,
      type: "POST",
      url: this.urlValue,
      data: input_data,
      dataType: 'json',
      timeout: 120000
    }).done(function(data) {
        // window.loader.dialog('close');     
        if (typeof data[0] === 'number') {
          if(data[0] == 0)
            setTimeout(() => { alert("Smth went wrong. Please try again later! Error type:\n" + data[1])}, 500);
          else
            setTimeout(() => { alert(data[1])}, 500);
        } else { 
          if ($("#container_" + input_data.type).text().length == 0) {
            this.index = 0;
            this.answer = data[0] + "\n";
          } else {
            this.index = $("#container_" + input_data.type).text().length;
            this.answer += data[0] + "\n";
          }
          $("#counter_" + input_data.type).text(this.countWords(this.answer) + ' words');
          eval('this.typewriter_' + input_data.type + '()');
        }
    }).fail(function( jqXHR, textStatus ) {
        window.loader.dialog('close');
        alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
    });
  }
}