import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ 
    "ai_type",
    "audience", "topics", 
    "topic", "length", "key_points",
    "kw_1", "kw_2", "kw_3", "outline", 
    "container_1", "container_2", "container_3" ]
    
  static values = {
    url: String
  }
    
  initialize() {
    this.initDictation()
    $(this.ai_typeTarget).focus()
    this.index = 0
    this.answer = ""
  }
  
  initDictation() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
        $(".fa-microphone").hide();
        return
    }
    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = "en-US";
    this.recognition.onresult = function(event) {
        if (event.results.length > 0) {
            var current = event.results[event.results.length-1][0]
            var result = current.transcript;
            window.textfield.val(result)
        }
    }

    this.recognition.onerror = function(event) {
      console.log(`Error occurred in recognition: ${event.error}`)
    }
  }

  record(event) {
    if (!this.recognition) {
        alert("Only Chrome and Firefox are supported")
        return
    }
    if(!$(event.target).hasClass("blink")) {
        window.textfield = $(event.target).parent().find('*').filter(':input:visible:first')
        this.recognition.start();
        $(".fa-microphone").not($(event.target)).hide();
        $(event.target).addClass("blink")
    } else {
        $(".fa-microphone").show();
        $(event.target).removeClass("blink")
        this.recognition.stop() 
    }
  }

  
  typewriter_1() {
    if (this.index < this.answer.length) {
        this.container_1Target.innerHTML += this.answer.charAt(this.index);
        this.index++;
        setTimeout(this.typewriter_1.bind(this), 3);
    }
  }
  
  typewriter_2() {
    if (this.index < this.answer.length) {
        this.container_2Target.innerHTML += this.answer.charAt(this.index);
        this.index++;
        setTimeout(this.typewriter_2.bind(this), 3);
    }
  }
  
  typewriter_3() {
    if (this.index < this.answer.length) {
        this.container_3Target.innerHTML += this.answer.charAt(this.index);
        this.index++;
        setTimeout(this.typewriter_3.bind(this), 3);
    }
  }
  
  autoSize(event) {
    var el = $(event.target)[0];
    el.style.height = 0;
    el.style.height = (el.scrollHeight) + "px";
  }
  
  clear(event) {
    $(event.target).closest('.inputs').find('input, textarea').not('input[type="submit"]').val('');
    $(event.target).closest('.inputs').find('input, textarea').first().focus();
    var id = $(event.target).closest('.inputs').attr('id').slice(-1);
    $("#container_" + id)[0].innerHTML = '';
    $("#counter_" + id).text('0 words');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(event.target).closest('.inputs').offset().top
    }, 1000);  
  }
  
  copy(event) {
    var $temp = $("<textarea>");
    $("body").append($temp);
    $temp.val($(event.target).parent().nextAll('div:first').text()).select();
    document.execCommand("copy");
    $temp.remove();
    $('.flashes').show();
    setTimeout(() => { $('.flashes').hide()} , 2000);
  }
  
  countWords(s) {
      s = s.replace(/(^\s*)|(\s*$)/gi,"");
      s = s.replace(/[ ]{2,}/gi," ");
      s = s.replace(/\n /,"\n"); 
      return s.split(' ').filter(function(str){ return str!=""; }).length;
  }
  
  generate(event) {
    event.preventDefault();
    // window.loader = $("#dialog-loader").dialog({
    //     dialogClass: "no-close",
    //     modal: true
    // });
    setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'})}, 50);
    var input_data = { 
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      ai_type: this.ai_typeTarget.value,
      ai_style: $('input[name="style_sample"]:checked').val(),
      model: $('input[name="model"]:checked').val()
    }
    var fieldset = $(event.target).closest('.inputs').attr('id');
    switch (fieldset) {
      case "input-fields-1": input_data = {
          ...input_data,
          type: 1,
          audience: this.audienceTarget.value,
          topics: this.topicsTarget.value,
          content: this.container_1Target.textContent
        }
        break;
      case "input-fields-2": input_data = {
          ...input_data,
          type: 2,
          topic: this.topicTarget.value,
          length: this.lengthTarget.value,
          key_points: this.key_pointsTarget.value,
          content: this.container_2Target.textContent
        }
        break;
      case "input-fields-3": input_data = {
          ...input_data,
          type: 3,
          kw_1: this.kw_1Target.value,
          kw_2: this.kw_2Target.value,
          kw_3: this.kw_3Target.value,
          outline: this.outlineTarget.value,
          audience: this.audienceTarget.value,
          topic: this.topicTarget.value,
          length: this.lengthTarget.value,
          content: this.container_3Target.textContent
        }
        break;
    }
    $.ajax({
      context: this,
      async: true,
      type: "POST",
      url: this.urlValue,
      data: input_data,
      dataType: 'json',
      timeout: 120000
    }).done(function(data) {
        // window.loader.dialog('close');  
        if (typeof data[0] === 'number') {
          if(data[0] == 0)
            setTimeout(() => { alert("Smth went wrong. Please try again later! Error type:\n" + data[1])}, 500);
          else
            setTimeout(() => { alert(data[1])}, 500);
        } else { 
          if ($("#container_" + input_data.type).text().length == 0) {
            this.index = 0;
            this.answer = data[0] + "\n";
          } else {
            this.index = $("#container_" + input_data.type).text().length;
            this.answer += data[0] + "\n";
          }
          $("#counter_" + input_data.type).text(this.countWords(this.answer) + ' words');
          eval('this.typewriter_' + input_data.type + '()');
        }
    }).fail(function( jqXHR, textStatus ) {
        window.loader.dialog('close');
        alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
    });
  }
}