// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
const images = require.context('../images', true);

import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

import "activeadmin_addons";
import "active_material";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "controllers";

require("jquery")

window.$ = window.jQuery = jQuery;

document.addEventListener('DOMContentLoaded', () => {
  // right filter sidebar
  const sidebar = document.querySelector('#settings')
  if (sidebar) {
    sidebar.addEventListener('click', event => {
      const insideSection = document.querySelector('#settings_section')
      if (!(event.target === insideSection || insideSection.contains(event.target))) {
        sidebar.classList.toggle('settings_open')
      }
    })
  }
	
	const menuButton = document.querySelector('#site_title')
	const menu = document.querySelector('#tabs')

	menuButton.addEventListener('click', event => {
	  const logo = document.querySelector('#site_title_image')
	  const forbiddenLinks = event.target === logo ||
	      logo.contains(event.target) 
	  if (!forbiddenLinks) {
	    menu.classList.toggle('tabs_open')
	  }
	});
	
  // close left menu sidebar on any click outside
  document.body.addEventListener('click', event => {
    const forbiddenLinks = event.target === sidebar ||
        sidebar.contains(event.target)
    if (sidebar.classList.contains('settings_open') && !forbiddenLinks) {
      sidebar.classList.remove('settings_open')
    }
  })
	
	$('div[contenteditable]').keydown(function(e) {
	    if (e.keyCode === 13) {
	        document.execCommand('insertHTML', false, "\n");
	        return false;
	    }
	});
	
	$('.gpt-model').change(function() {
		var message = ''
		if($(this).val()=='gpt-4') {
			message = 'GPT-4 takes its time for excellence. It will take a minute.';
		} else {
			message = 'GPT-3.5 is refining your output. Stand by.';
		}
		$("#ui-id-1").text(message);
		$('#dialog-loader').attr('title', message);
	})
})